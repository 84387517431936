import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import useToggle from "../components/UseToggle"
import CardsSorter from "../components/CardsSorter"
import Card from "../components/atoms/Card"
import Search from "../components/atoms/Search"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FaUsers, FaPencilRuler, FaSortAlphaDown } from "react-icons/fa"

const IndexPage = ({ data }) => {
  const [showContacts, showingContacts] = useToggle(true)
  return (
    <>
      <Seo title="Contacts by Team" />
      <section className="container p-8 mt-16 lg:mt-32 md:p-16">
        <h1 className="mb-16">Contacts by Team <FaSortAlphaDown className="inline-block ml-2 -mt-4 text-base opacity-50" /></h1>
        <div class="flex flex-col lg:flex-row lg:items-start gap-32 lg:gap-40">
          <div className="lg:w-2/5">
            <h2 className="mb-8">Search for a specific contact below:</h2>
            <Search />
          </div>
          <div className="lg:w-3/5" id="jumpToTeams">
            <h2 className="mb-8">Jump to team:</h2>
            <ul className="flex flex-row flex-wrap gap-4">
              {data.teams.edges.map((team, key) => (
                <li className="flex" key={key}>
                  <AnchorLink
                    to={"#bkmrk-" + team.node.slug}
                    title={team.node.title}
                    className="px-6 py-3 border border-opacity-50 rounded-full text-2xs lg:text-xs text-secondary-dark border-grey-medium hover:bg-secondary-default hover:text-white focus:text-white focus:bg-secondary-default dark:text-white"
                  >
                    {team.node.title}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div id="sortedTeams">
          <ul className="flex flex-col">
            <div className="w-full">
              <button
                onClick={showingContacts}
                className="inline-flex flex-row items-center px-6 py-3 mt-32 mb-8 mr-auto space-x-4 text-sm font-light transition duration-300 bg-transparent border border-opacity-50 rounded-full hover:bg-grey-medium hover:bg-opacity-20 lg:mt-0 border-grey-medium focus:outline-none"
              >
                <span>Show all contacts</span>
                <div className="flex flex-row items-center w-16 p-px border border-opacity-50 rounded-full border-grey-medium">
                  <span
                    className={
                      "w-8 h-8 rounded-full transition-all duration-300 " +
                      (showContacts === true
                        ? " bg-primary-default ml-auto"
                        : " bg-grey-dark dark:bg-grey-light ml-0")
                    }
                  ></span>
                </div>
              </button>
              {data.teams.edges.map((team, key) => (
                <details
                  id={"bkmrk-" + team.node.slug}
                  key={key}
                  open={showContacts ? "open" : null}
                  className="mb-32 team-wrapper"
                >
                  <summary className="py-12 my-16 text-lg font-semibold list-none transition duration-300 border-t border-b cursor-pointer lg:text-2xl font-display text-secondary-default team-name text-secondary border-grey-medium border-opacity-20 dark:text-white hover:opacity-70">
                    {team.node.title}
                  </summary>
                  <div
                    className={
                      "grid grid-cols-1 gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 "
                    }
                  >
                    {data.contacts.edges.map((contact, ckey) => (
                      <CardsSorter
                        key={ckey}
                        contactName={contact.node.name}
                        contactRole={contact.node.role}
                        contactImage={contact.node.image}
                        contactTeam={contact.node.team.title}
                        contactEmail={contact.node.email}
                        contactDirectLine={contact.node.directLine}
                        contactManager={contact.node.manager}
                        contactDiscipline={contact.node.discipline?.title}
                        contactDisciplineHead={contact.node.disciplineHead}
                        showManagerBorder={ true }
                        contactNotWorkingMonday={contact.node.monday}
                        contactNotWorkingTuesday={contact.node.tuesday}
                        contactNotWorkingWednesday={contact.node.wednesday}
                        contactNotWorkingThursday={contact.node.thursday}
                        contactNotWorkingFriday={contact.node.friday}
                        contactWorkingHours={contact.node.workingHours}
                        currentTeam={team.node.title}
                        contactSlackUserId={contact.node.slackUserId}
                        contactBiography={contact.node.biography}
                        lineManager={contact.node.lineManager?.name}
                        sortBy="team"
                      />
                    ))}
                  </div>
                </details>
              ))}
            </div>
          </ul>
        </div>
      </section>
      <section
        style={{ display: "none" }}
        id="allContacts"
        className="container grid grid-cols-1 gap-8 p-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5"
      >
        {data.contacts.edges.map((contact, ckey) => (
          <Card
            key={ckey}
            contactName={contact.node.name}
            contactRole={contact.node.role}
            contactImage={contact.node.image}
            contactTeam={contact.node.team.title}
            contactEmail={contact.node.email}
            contactDirectLine={contact.node.directLine}
            contactManager={contact.node.manager}
            contactDiscipline={contact.node.discipline?.title}
            contactNotWorkingMonday={contact.node.monday}
            contactNotWorkingTuesday={contact.node.tuesday}
            contactNotWorkingWednesday={contact.node.wednesday}
            contactNotWorkingThursday={contact.node.thursday}
            contactNotWorkingFriday={contact.node.friday}
            contactWorkingHours={contact.node.workingHours}
            contactSlackUserId={contact.node.slackUserId}
            contactBiography={contact.node.biography}
            lineManager={contact.node.lineManager?.name}
            showTeam={true}
          />
        ))}
      </section>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query TeamContacts {
    contacts: allDatoCmsContact(sort: { fields: [team___title, name] }) {
      edges {
        node {
          id
          team {
            id
            title
          }
          name
          email
          directLine
          role
          manager
          disciplineHead
          lineManager {
            name
          }
          image {
            gatsbyImageData(width: 800, height: 800, placeholder: BLURRED)
          }
          discipline {
            title
          }
          monday
          tuesday
          wednesday
          thursday
          friday
          workingHours
          slackUserId
          biography
        }
      }
    }
    teams: allDatoCmsTeam(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    disciplines: allDatoCmsDiscipline {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`
